<template>
  <div>
    <CInput
        :is-valid="notNull"
        v-model="productGroup.name"
        v-on:change="updateItem"
        :readonly="!allowEdit"
        description="Product Group Name"
    />
    <CTextarea
          v-model="productGroup.description"
          v-on:change="updateItem"
          :readonly="!allowEdit"
          placeholder="Add description"
          @input="productGroup.description = $event !== '' ? $event : null"
      />
  </div>
</template>

<script>
const clonedeep = require('lodash.clonedeep')

export default {
  name: "ProductGroupForm",
  props: ["productGroupIn"],
  methods: {
    updateItem(){
      const data = clonedeep(this.productGroup)
      this.$emit('update-item', data);
    },
    notNull(val) {
        return val !== undefined && val !== null && val !== ''
    }
  },
  computed: {
    productGroup(){
      return this.productGroupIn? this.productGroupIn: {}
    },
    allowEdit: {
      get() {
        return this.$store.state.allowEdit
      },
      set (){
      }
    }
  },
  watch:{
    productGroupIn(){
      this.updateItem();
    }
  }
}
</script>

<style scoped>
</style>
