<template>
  <CButtonGroup data-sel="btns-table">
    <CButton
        data-sel="btn-view"
        size="sm"
        color="dark"
        variant="outline"
        @click="$emit('view', true)"
    >
      <CIcon name="cil-zoom"/>
    </CButton>
    <CButton
        data-sel="btn-edit"
        size="sm"
        color="info"
        variant="outline"
        @click="$emit('edit', true)"
        :disabled="disableEditButtons"
    >
      <CIcon name="cil-pen"/>
    </CButton>
    <CButton
        data-sel="btn-delete"
        size="sm"
        color="danger"
        variant="outline"
        @click="$emit('delete-prompt', true)"
        :disabled="disableEditButtons"
    >
      <CIcon name="cil-trash"/>
    </CButton>
  </CButtonGroup>
</template>

<script>
export default {
  name: "TableButtonGroup",
  props: ["disableEdits"],
  computed:{
    disableEditButtons(){
      return this.disableEdits? this.disableEdits: false
    }
  }
}
</script>