<template>
  <div>
    <CModal
        title="New Product Group"
        :show.sync="showModal"
        @update:show="closeAddModal"
    >
      <CAlert
          v-if="error"
          color="danger"
          closeButton
          @update:show="removeError">
        {{error}}
      </CAlert>
      <ProductGroupForm
          v-bind:productGroupIn="productGroup"
          v-on:update-item="updateItem"
      />
    </CModal>
  </div>
</template>

<script>
import gql from "graphql-tag";
import ProductGroupForm from "@/apps/contracts/components/ProductGroupForm";

const createProductGroup = gql`
  mutation createProductGroup($productGroup: ProductGroupInput){
    createProductGroup(productGroup: $productGroup) {
      nid
      name
      description
    }
  }
`

export default {
  name: "ProductGroupAdd",
  components: {
    ProductGroupForm
  },
  data(){
    return {
      productGroup: {},
      productGroupSave: {},
      error: null
    }
  },
  computed: {
    showModal: {
      get: function() {
        return this.$store.state.addModalShow
      },
      set: function (){
      }
    }
  },
  methods: {
    updateItem(data){
      this.productGroupSave = data
    },
    closeAddModal(status, evt, accept){
      if (accept) {
        this.$apollo.mutate({
          mutation: createProductGroup,
          variables: {
            productGroup: this.productGroupSave
          },
        })
        .then(res => {
          this.$emit('add-item', res.data.createProductGroup);
          this.productGroup = {};
          this.productGroupSave = {};
          this.$store.commit('set', ['addModalShow', false]);
        })
        .catch(error => {
          console.error(error);
          this.error = error.message;
        })
       }else{
        this.productGroup = {};
        this.productGroupSave = {};
        this.$store.commit('set', ['addModalShow', false]);
       }
    },
    removeError(hide){
      if(!hide){
        this.error = null
      }
    }
  }
}
</script>
